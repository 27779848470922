

















































import { Component, Inject, Vue } from 'vue-property-decorator'
import { ProjectDetailViewModel } from '../../viewmodels/project-detail-viewmodel'

@Component({
  components: {
    LabelStatus: () => import('../label-status.vue'),
    AboutContent: () => import('../about-content.vue'),
  },
})
export default class Description extends Vue {
  @Inject() vm!: ProjectDetailViewModel
  readonly highlightContents = [
    'Ark Rivals is an epic sci-fi action strategy NFT game of resource wars, global economy control, political domination and is based on user-generated content.',
    'One of the few crypto games that are Apple Arcade candidates, providing engaging gameplay and high-quality NFTs.',
    'The team has raised $3.5M in a private round from top VCs, such as Kardia Ventures, Morningstar Ventures, Infinity Ventures, Bitscale, Formless Capital, Youbi, Axia 8 Ventures and others.',
  ]

  readonly productHeading = 'A Sci-fi action strategy game based on user-generated content.'
  readonly productContents = [
    'Taking inspiration from the modern action-strategy gameplay format combined with some unique tweaks and sci-fi art, Ark Rivals is about gaining power and resources to establish domination in the galaxy. Ark Rivals is a Sci-fi action strategy NFT-game based entirely on user-generated content. The game combines elements from highly successful games such as Clash of Clans and Rise of Kingdom.',
    'The core game loop of Ark Rivals involves purchasing NFT land, building up a base for defense and resource mining, raising an airship army (Arks) to wage war against other bases, and looting all the resources to show dominance among the five factions in the solar system.',
    'The project\’s goal is to attract mainstream users playing traditional mobile games as well as crypto-savvy gamers familiar with play-to-earn games. Ark Rivals takes the free-to-play approach and removes barriers of initial payment, investment in in-game tokens, and doesn’t require high skills to begin playing.',
  ]
}
